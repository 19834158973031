<template>
  <v-sheet class="fluid-window back-img">
    <div class="transparent content-top-padding">
      <h1 class="white--text text-center client-title-div mb-8">
        <i18n path="detail.visits" />
      </h1>
    </div>
    <v-data-table
      dark
      :headers="headers"
      :items="items"
      item-key="idx"
      class="mx-2 main-data-table transparent"
      :footer-props="foot_props"
      :no-data-text="$t('nodata')"
      show-expand
      single-expand
      @item-expanded="showExpanded"
    >
      <!-- <template #item.action="{item}">
        <v-btn icon @click="showVisitDetail(item.idx)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>-->
      <template #expanded-item="{headers}">
        <td :colspan="headers.length">
          <v-chip v-for="d in details" :key="d.idx" class="ml-3">
            <v-icon>mdi-human-scooter</v-icon>
            <span>{{d.service}}</span>
            <template v-if="d.workout">
              <v-icon class="ml-3 mr-1">mdi-calendar-month</v-icon>
              <span>{{d.workout}}</span>
              <v-icon class="ml-3 mr-1">mdi-weight-lifter</v-icon>
              <span>{{d.coach}}</span>
              <v-icon class="ml-3 mr-1">mdi-greenhouse</v-icon>
              <span>{{d.room}}</span>
            </template>
          </v-chip>
        </td>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
export default {
  name: "Cabinet",
  data() {
    return {
      details: [],
      items: [],
      headers: [
        {
          text: this.$t("fields.sportclub"),
          value: "scname",
        },
        {
          text: this.$t("fields.key"),
          value: "keyid",
          align: "right",
        },
        {
          text: this.$t("fields.from_date"),
          value: "fromdate",
        },
        {
          text: this.$t("fields.to_date"),
          value: "todate",
        },
        // {
        //   text: "",
        //   value: "action",
        //   width: 60,
        // },
      ],
      foot_props: {
        itemsPerPageOptions: [14, 20, 50, 100], // -> Add this example
        showFirstLastPage: true,
        firstIcon: "mdi-arrow-collapse-left",
        lastIcon: "mdi-arrow-collapse-right",
        prevIcon: "mdi-less-than",
        nextIcon: "mdi-greater-than",
        itemsPerPageText: this.$t("rowPerPage"),
      },
    };
  },
  methods: {
    showVisitDetail(idx) {
      this.$api.visitDetail(idx).then((r) => {
        console.log(r);
      });
    },
    showExpanded(param) {
      this.details = [];
      if (param.value) {
        this.$api.visitDetail(param.item.idx).then((r) => {
          this.details = [...r];
        });
      }
    },
  },
  mounted() {
    this.$api.visits().then((r) => {
      try {
        this.items = [...r];
      } catch (error) {
        this.items = [];
      }
    });
  },
};
</script>
<style scoped>
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
.main-row {
  padding-top: 160px;
}
</style>
